<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div>
        <table-Reason-Processing :reasonProcessing="reasonProcessing" @editForm="editForm"/>
    </div>
    <Modal-Reason-Processing @refresh="refresh" />
    <Modal-Reason-Processing-Edit @refresh="refresh" :id="id"/>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
</div>
</template>
<script>
import tableReasonProcessing from '@/views/component/Table/tableReasonProcessing.vue'
import ModalReasonProcessing from '@/views/component/Modal/ModalReasonProcessing/ModalReasonProcessing.vue'
import ModalReasonProcessingEdit from '@/views/component/Modal/ModalReasonProcessing/ModalReasonProcessingEdit.vue'
export default {
    components: {
        tableReasonProcessing,
        ModalReasonProcessing,
        ModalReasonProcessingEdit,
    },
    data(){
        return {
            reasonProcessing: [],
            showPreloader: false,
            id: undefined,
            fields: [
                { key: 'id', label: 'Id'},
                { key: 'name', label: 'Имя'},
                { key: 'is_active', label: 'Статус'}
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods:{
        refresh(){
            this.showPreloader = true
            this.$http.get(`driver-profile-causes`)
                .then(res => {
                    this.reasonProcessing = res.data
                    this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.reasonProcessing = res.data
                        this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        openModal(){
            this.$bvModal.show('reasonProcessingEdit')
        },
        sendToParent(tableData){
            this.reasonProcessing = tableData
            this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.reasonProcessing = res.data
                    this.$store.commit('pageData/setdataCount', this.reasonProcessing.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }

}
</script>