<template>
    <div>
        <b-modal id="reasonProcessingEdit" title="Изменить данных причины" size="xs" ok-only ok-title="Изменить" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
            <b-row>
                <b-col cols="12">
                    <v-select
                        v-model="reasonProcessing.status_id"
                        placeholder="Статус"
                        label="name"
                        :reduce="status => status.id"
                        :options="statuses"
                        class="select-size-md" 
                        />
                </b-col>
                <b-col cols="12" class="mt-2">
                    <b-input v-model="reasonProcessing.name" placeholder="Наименования" />
                </b-col>
                <b-col class="mt-2">
                   <b-form-checkbox v-model="reasonProcessing.is_active" class="custom-control-primary">
                    {{ reasonProcessing.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editreasonProcessing">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
    },
    data(){
        return {
            reasonProcessing: {
                name: "",
                status_id: "",
                is_active: true,
            },
            statuses: [],
            submitButtonDisabled: false,
        }
    },
    methods:{
        editreasonProcessing(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`driver-profile-causes/${this.id.id}`, this.reasonProcessing)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    // this.$bvModal.hide('reasonProcessingEdit')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`driver-profile-causes/${this.id.id}/edit`)
                .then(res => {
                    this.reasonProcessing = res.data

            this.$http
                .get(`driver-profile-statuses`)
                .then(res => {
                    this.statuses = res.data
                })
            })
            // this.clearData()
        },
        closeModal(){
            this.$bvModal.hide('reasonProcessingEdit')
            // this.clearData()
        },
        // clearData(){
        //     this.reasonProcessingEdit = {}
        // }
    }
}
</script>